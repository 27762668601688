<template>
    <div>
        <div style="display:flex;">
        <div class="top" style="width:100%">
            <!-- 选择城市、类型 -->
          <div class="selectCampus">
            <div class="selectCampus">
                <div style="display:flex;align-items: center;margin-right:5px;">请选择城市：</div>
                <el-select v-model="query.regionId" placeholder="城市" @change="getData">
                    <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div v-if="title =='器材同步'" class="selectCampus">
              <div style="display:flex;align-items: center;margin-right:5px;">目标城市：</div>
              <el-select v-model="query.targetRegionId" multiple placeholder="城市">
                <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <!--            <div class="selectCampus" v-if="query.regionId">-->
<!--                <div style="display:flex;align-items: center;margin-right:5px;">请选择类型：</div>-->
<!--                <el-select placeholder="类型" v-model="query.flag" @change="getData">-->
<!--                    <div>-->
<!--                      <el-option label="中招体育" value="1"></el-option>-->
<!--                      <el-option label="少年" value="2"></el-option>-->
<!--                      <el-option label="高考体育" value="4"></el-option>-->
<!--                    </div>-->
<!--                </el-select>-->
<!--            </div>  -->
          </div>
          <!-- 器材table -->
          <div v-if="title!=='器材同步'">
            <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" :row-style="{ height: 12 + 'px' }"
                :cell-style="{ padding: 6 + 'px' }" row-key="id" border
                :tree-props="{ children: 'list', hasChildren: 'hasChildren' }"  >
                    <el-table-column prop="name" label="器材名称" width="180"/>
                    <el-table-column label="库存量" width="120">
                        <template #default="scope">
                            <el-tag :type="scope.row.num < 10 ? 'danger' : ''">{{ scope.row.num }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" sortable width="180"/>
                    <el-table-column label="所属城市" >
                    <template #default="scope">{{ scope.row.regionName }}</template>
                    </el-table-column>
                    <el-table-column label="操作" >
                        <template #default="scope">
                            <el-button  type="text" size="small" @click="handleEdit(scope.$index, scope.row)">调拨</el-button>
                        </template>
                    </el-table-column>
            </el-table>
          </div>
        </div>
        <el-dialog title="器材挑拨" v-model="editVisible">
            <div class="top" style="width:100%">
            <!-- 选择城市、类型 -->
                <div class="selectCampus">
                    <div class="selectCampus">
                        <div style="display:flex;align-items: center;margin-right:5px;">请选择城市：</div>
                        <el-select v-model="param.newRegionId" placeholder="城市" @change="getData">
                            <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>      
<!--                    <div class="selectCampus">-->
<!--                        <div style="display:flex;align-items: center;margin-right:5px;">请选择类型：</div>-->
<!--                        <el-select placeholder="类型" v-model="param.newRegionFlag">-->
<!--                            <div>-->
<!--                            <el-option label="中招体育" value="1"></el-option>-->
<!--                            <el-option label="少年" value="2"></el-option>-->
<!--                            <el-option label="高考体育" value="4"></el-option>-->
<!--                            </div>-->
<!--                        </el-select>-->
<!--                    </div>  -->
                </div>
                <div  class="selectCampus">
                    <div  class="selectCampus"> 
                        <div style="line-height:39px">调拨数量：</div>
                        <el-input-number @focus="tipMessage" v-model="param.transferNumber"/>      
                    </div>
                    <div  class="selectCampus"> 
                        <div style="line-height:39px">仓库剩余数量：</div>
                        <el-input-number  v-model="residueNum" disabled />      
                    </div>                            
                </div>
            </div>
            <template #footer>
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="Transform" >确 定</el-button>
            </template>
        </el-dialog>
        <!-- <div class="middle" @click="synchronization">一键同步</div> -->
        <!-- <div class="right">
          <div class="selectCampus">
            <div style="display:flex;align-items: center;margin-right:5px;"> 选择同步城市: </div>
            <el-select v-model="query.regionId" placeholder="城市" @change="addSelectCity">
                <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
          </div>
        </div> -->
      </div>
    </div>
</template>
<script>
import { equList ,
     equipTransfer
     } from "@/api/equipment";
    export default{ 
        props:{
            regionList:Array,
            title:String
        },
        data(){
            return{
                residueNum:0,
                editVisible:false,
                param:{
                    id:null,
                    newRegionId:null,
                    oldRegionId:null,
                    transferNumber:0,
                },
                query: {
                    keyword: "",
                    regionId: "",
                    // flag: null,
                    pageIndex: 1,
                    pageSize: 20,
                    targetRegionId:[]
                },
                tableData: [],
            }
        },
        methods:{
            //确认挑拨
            Transform(){
                this.param.oldRegionId = this.query.regionId;
                if(this.param.newRegionId && this.param.transferNumber>0){
                    equipTransfer(this.param).then(res=>{
                        if(res.code==200){
                            this.$message({
                                message: '调拨成功',
                                type: 'success'
                            });
                            this.editVisible = false;
                            this.query.keyword = "";
                            this.query.regionId = "";
                            this.query.pageIndex = 1;
                            this.query.pageSize = 20;
                            this.getData();
                        }else{ 
                            this.$message.error(res.message);
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                }else{ 
                    this.$message({
                        message: '请填写完整信息',
                        type: 'warning'
                    });
                    return false;
                }
            },
            //提示数量
            tipMessage(){
                if(this.residueNum>0){
                    this.$message({
                        message: '库存剩余'+this.residueNum,
                        type: 'warning'
                    });
                }else{
                    this.$message({
                        message: '没有剩余设备',
                        type: 'warning'
                    });
                }
            },
            //勾选
            handleEdit(index,val){ 
                this.editVisible = true
                this.residueNum = val.num
                this.param.id = val.id
            },
            async getData() {
                this.tableData = []
            // 表格数据
            await equList(this.query).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.records;
                } else {
                    this.$message.error("获取表格数据失败");
                }
            })
        },
        },
        //监听传来title的值
        watch: {
            title: {
                handler: function (newVal) {
                    console.log(newVal);
                },
                deep: true, // 深度监听
                immediate: true, //立即执行
            },
        }
    }
</script>
<style scope>
.middle{ 
  background: skyblue;
  color: #fff;
  border-radius: 20px;
  writing-mode: vertical-rl; /* 从右到左 */
  text-orientation: upright; /* 保持正常显示 */
  text-align: center;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
}
.selectCampus{ 
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
}

</style>