<template>
  <div>
    <div style="padding: 20px">
    <div class="handle-box">
        <el-button type="primary" class="handle-del mr10" @click="addVisible = true">新增器材</el-button>
        <el-button type="primary" class="handle-del mr10" @click="synchronize = true,transTitle='器材同步'">器材同步</el-button>
        <el-button type="primary" class="handle-del mr10" @click="synchronize = true,transTitle='器材调拨'">器材调拨</el-button>
<!--        <el-select placeholder="类型" style="width: 120px;margin-right: 10px" v-model="query.flag">-->
<!--          <div v-if="sysStudentType == 3">-->
<!--            <el-option label="全部"></el-option>-->
<!--            <el-option label="中招体育" value="1"></el-option>-->
<!--            <el-option label="少年" value="2"></el-option>-->
<!--            <el-option label="高考体育" value="4"></el-option>-->
<!--          </div>-->

<!--          <div v-if="sysStudentType != 3">-->
<!--            <el-option v-if="sysStudentType == 1" label="中招体育" value="1"></el-option>-->
<!--            <el-option v-else label="少年" value="2"></el-option>-->
<!--            <el-option label="高考体育" value="4"></el-option>-->
<!--          </div>-->
<!--        </el-select>-->
        <el-select v-model="query.regionId" placeholder="城市" class="handle-select mr10">
          <el-option v-for="(item, index) in regionList" :key="index" @change="selectCity1(index)" :label="item.name"
            :value="item.id"></el-option>
        </el-select>
        <el-input v-model="query.keyword" placeholder="器材名称" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
        <el-button type="info" icon="Search" @click="reset">重置</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" :row-style="{ height: 12 + 'px' }"
        :cell-style="{ padding: 6 + 'px' }" row-key="id" border
        :tree-props="{ children: 'list', hasChildren: 'hasChildren' }">
        <el-table-column prop="name" label="器材名称" width="180">
            <template #default="scope">
            {{ scope.row.name }}  {{+ '---' + scope.row.equCode?scope.row.equCode:''}}
          </template>
        </el-table-column>
        <!-- <el-table-column  label="器材名称与编码" width="220" prop="name"> -->
          <!-- <template #default="scope">
            {{ scope.row.name + '---' + scope.row.equCode?scope.row.equCode:''}}
          </template> -->
        <!-- </el-table-column> -->
        <el-table-column label="库存量" width="120">
          <template #default="scope">
            <el-tag :type="scope.row.num < 10 ? 'danger' : ''">{{ scope.row.num }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable width="180">
        </el-table-column>
        <el-table-column label="所属仓库" >
          <template #default="scope">{{ scope.row.equStoreName}}</template>
        </el-table-column>
        <el-table-column label="规格" >
          <template #default="scope">{{ scope.row.equUnit }}</template>
        </el-table-column>
        <el-table-column label="价格" prop="money">
          <template #default="scope"><span style="color:darkgrey"> {{ scope.row.money?scope.row.money:'暂无价格' }}</span></template>
        </el-table-column>
     
        <el-table-column label="状态">
          <template  #default="scope">
            <div v-show="false">{{ scope.row.state }}</div> 
            <el-tooltip :content="scope.row.state=='1'? '关闭后此将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="setState(scope.row)"
                  v-model="scope.row.state"
                  :active-value='1'
                  :inactive-value='0'
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column> 
        
        <el-table-column  label="操作" width="180" align="center">
          <template #default="scope">
            <el-button :disabled="scope.row.childList ? true : false" type="text" icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button v-permission="['operation']"  type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
          :page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改器材" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="器材名称">
          <el-input  v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="库存量">
          <el-input type="number" v-model="form.num"></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <el-input type="number" v-model="form.money"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="qx">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增弹出框框 -->
    <el-dialog title="器材信息录入" v-model="addVisible" width="900px">
     <el-form :rules="rules" ref="addForm" :model="addForm" label-width="140px" style="padding-right: 50px">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="name" label="器材名称">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">-->
<!--            <el-form-item prop="regionId" label="类型">-->
<!--              <el-select placeholder="类型" v-model="addForm.flag">-->
<!--                <div v-if="sysStudentType == 3">-->
<!--                  <el-option label="全部"></el-option>-->
<!--                  <el-option label="中招体育" value="1"></el-option>-->
<!--                  <el-option label="少年" value="2"></el-option>-->
<!--                  <el-option label="高考体育" value="4"></el-option>-->
<!--                </div>-->

<!--                <div v-if="sysStudentType != 3">-->
<!--                  <el-option v-if="sysStudentType == 1" label="中招体育" value="1"></el-option>-->
<!--                  <el-option v-else label="少年" value="2"></el-option>-->
<!--                  <el-option label="高考体育" value="4"></el-option>-->
<!--                </div>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="8">
            <el-form-item prop="regionId" label="所属城市">
              <el-select v-model="addForm.regionId" placeholder="城市" @change="addSelectCity">
                <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="器材类型">
              <el-select clearable="true" v-model="addForm.pid" placeholder="默认不选为顶级"  :popper-append-to-body='false'
                @change="selectEq" class="handle-select mr10">
                <el-option v-for="(item) in eqList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="库存量">
              <el-input type="number" :disabled="isDisabled" placeholder="请选择器材类型后再录入数量" v-model="addForm.num">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="价格">
              <el-input type="number"  :disabled="disabledPid"  placeholder="选择器材类型填选价格" v-model="addForm.money">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="器材编码">
              <el-input v-model="addForm.equCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="器材单位">
              <el-input v-model="addForm.equUnit" placeholder="个，双，根"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
     </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd('addForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 器材同步 -->
    <el-dialog :title='transTitle' v-model="synchronize" width="900px" >
      <EquipMenttrans :regionList="regionList" :title="transTitle" ref="EquipMenttrans"/>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="synchronize = false">取 消</el-button>
          <el-button type="primary" @click="equipMentTrans" v-loading.lock="loadings">{{loadings?'同步中':'同步'  }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// import { 
//   editSate 
// } from "@/api/coach";
import { equList, add, del, edit,editState , equipSync} from "@/api/equipment";
import { selectAreaByExpress } from "@/api/area";
import EquipMenttrans from './components/EquipMenttrans.vue';
export default {
  name: "Equipment",
  components: {
    EquipMenttrans,
  },
  data() {
    return {
      loadings:false,
      transTitle:'',
      synchronize:false,//同步、调拨弹窗
      disabledPid:true,
      sysStudentType: localStorage.getItem('studentType'),
      query: {
        keyword: "",
        regionId: "",
        // flag: null,
        pageIndex: 1,
        pageSize: 10
      },
      rules: {
        name: [
          { required: true, message: '请输入器材名称', trigger: 'blur' },
        ],
        regionId: [
          { required: true, message: '请选择所属城市', trigger: 'change' }
        ]
      },
      isDisabled: true,
      eqList: [],
      regionList: [],
      campusList: [],
      campusList1: [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      addForm: {
        code:null,
        pageIndex: 1,
        pageSize: 1000
      },
      idx: -1,
      id: -1
    };
  },

  created() {
    this.getData();
    // 城市下拉数据
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })

  },
  methods: {
    //一件同步 
    equipMentTrans(){
      let obj  = { 
        // oldRegionFlag:Number( this.$refs.EquipMenttrans.query.flag),
        oldRegionId:Number(this.$refs.EquipMenttrans.query.regionId),
        targetRegionId:this.$refs.EquipMenttrans.query.targetRegionId
      }
      console.log(":Aas")
      if( obj.oldRegionId){
        this.loadings = true
        equipSync(obj).then(res=> {
          if(res.code == 200){
            this.loadings = false
            this.$message.success("同步成功");
            // this.$refs.EquipMenttrans.query.flag = ''
            this.$refs.EquipMenttrans.query.regionId = ''
            this.synchronize = false
            this.getData()
          }else{ 
            this.$message.error(res.message);
          }
        })
      }else{ 
        this.$message.error("请选择同步城市");
      }
    },
    //选择框下拉加载，有BUG 暂时不用
    // loadmore() { 
    //   this.addForm.pageIndex++
    //    equList(this.addForm).then(res => {
    //     if (res.code == 200) {
    //       for (let index in res.data.records) {
    //         this.eqList.push({
    //           label: res.data.records[index].name + '-' + res.data.records[index].equCode,
    //           value: res.data.records[index].id
    //         })
    //       }
    //       console.log(this.eqList)
    //       this.addForm.pid = this.eqList[0].value
    //     }
    //   }).catch(() => {
    //   })
    // },
    getAreaListId(val) {
      this.query.areaList = val;
    },
    selectEq(e) {
      console.log(e);
      if (this.addForm.pid != null) {
        this.isDisabled = false
      }
    },
    // 新增器材时选择城市
    async addSelectCity() {
      this.addForm.keyword = ""
      this.eqList = []
      this.addForm.pageIndex = 1
      await equList(this.addForm).then(res => {
        if (res.code == 200) {
          for (let index in res.data.records) {
            this.eqList.push({
              label: res.data.records[index].name + '-' + res.data.records[index].equCode,
              value: res.data.records[index].id
            })
          }
          console.log(this.eqList)
          this.addForm.pid = this.eqList[0].value
        }
      }).catch(() => {
      })
    },
    // 获取数据
   async  getData() {
      // 表格数据
     await  equList(this.query).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },

    // 触发搜索按钮
    handleSearch() {    
      this.query.pageIndex = 1
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {   
      let str = ""
      if (row.childList) {
        str = "将会删除该分类下所有器材"
      }
      // 二次确认删除
      this.$confirm("确定要删除器材: " + row.name + " 吗? " + str, "提示", {
        type: "warning"
      }).then(() => {
        del(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
            this.getData()
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
   
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    //取消编辑
    qx() {     
      this.editVisible = false;
      this.getData()
      this.$message.info(`您取消了修改`);
    },
    // 保存编辑
    saveEdit() {
      edit(JSON.parse(JSON.stringify(this.form))).then(res => {
        this.editVisible = false;
        if (res.code == 200) {
          if (res.data > 0) {
            this.$message.success(`修改器材: ${this.form.name} 成功`);
          } else {
            this.$message.error(`修改器材: ${this.form.name} 失败`);
          }
        } else {
          this.$message.error(`修改器材: ${this.form.name} 失败,` + res.message);
        }
        this.getData()
      })

    },
    /**
     * 新增
     */
    saveAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add(JSON.parse(JSON.stringify(this.addForm))).then(res => {
            this.addVisible = false;
            if (res.code == 200) {
              if (res.data > 0) {
                this.$message.success(`新增器材: ${this.addForm.name} 成功`);
              } else {
                this.$message.error(`新增器材: ${this.addForm.name} 失败`);
              }
            } else {
              this.$message.error(res.message);
            }
            this.addForm.regionId = ''
            this.addForm.name = ''
            this.addForm.num = ''
            this.addForm.pid = ''
            this.getData()
          })
        } else {
          return false;
        }
      })

    },

    // 分页导航
    handlePageChange(val) {
   
      this.query.pageIndex = val
      this.getData();
    },
    // 选中城市  渲染校区
    selectCity1(val) {
    
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses
      }
    },

    // 编辑 选中城市  渲染校区
    selectCity2(val) {
      this.form.campusid = null
      this.addForm.campusid = null
      this.campusList1 = JSON.parse(JSON.stringify(this.regionList[val - 1])).campuses;
    },
    // 修改
    setState(row) {
      editState(row.id, row.state).then(res => {
        if (res.code == 200) {
          this.getData()
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })


    },
    // 重置
    reset() {
   
      this.query.regionId = ""
      this.query.campusId = ""
      this.query.type = ""
      this.query.keyword = ""
      this.query.pageIndex = 1
      this.getData()
    }
  },
  watch:{ 
    addForm: {
      handler(newVal) {  
             if(newVal.pid){ 
              this.disabledPid = false
             }else{ 
              this.disabledPid = true
              newVal.money=''
             }
      },
      deep: true  // 深度监听
    },
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>